var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-assign-devices" },
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { title: "Unassigned Devices" } },
            [
              _vm.user
                ? _c("div", [
                    _vm._v(
                      "Assigning to: " +
                        _vm._s(_vm.user.name) +
                        " (" +
                        _vm._s(_vm.user.email) +
                        ")"
                    )
                  ])
                : _vm._e(),
              _c("admin-device-list", {
                attrs: {
                  devices: _vm.deviceList,
                  "hide-advanced": "",
                  "show-fields": _vm.deviceFields
                },
                on: { change: _vm.selectChanged }
              }),
              _c(
                "div",
                { staticClass: "footer mt-2" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.clickClose()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.saveChanges()
                        }
                      }
                    },
                    [_vm._v("Assign Selected")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-tab", { attrs: { title: "Barcode Input" } }, [
            _c(
              "div",
              [
                _c("admin-barcode-input", {
                  ref: "deviceInput",
                  attrs: { "hide-email": "", "validate-imeis": "" }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "footer mt-2" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        return _vm.clickClose()
                      }
                    }
                  },
                  [_vm._v("Close")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        return _vm.saveChangesBarcode()
                      }
                    }
                  },
                  [_vm._v("Assign Selected")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }