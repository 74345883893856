<template>
  <div class="admin-assign-devices">
    <b-tabs>
      <b-tab title="Unassigned Devices">
        <div v-if="user">Assigning to: {{user.name}} ({{user.email}})</div>
        <admin-device-list v-on:change="selectChanged" v-bind:devices="deviceList" hide-advanced :show-fields="deviceFields"></admin-device-list>
        <div class="footer mt-2">
          <b-button class="button" @click="clickClose()">Close</b-button>
          <b-button class="button" @click="saveChanges()">Assign Selected</b-button>
        </div>
      </b-tab>
      <b-tab title="Barcode Input">
        <div>
          <admin-barcode-input hide-email validate-imeis ref="deviceInput"></admin-barcode-input>
        </div>
        <div class="footer mt-2">
          <b-button class="button" @click="clickClose()">Close</b-button>
          <b-button class="button" @click="saveChangesBarcode()">Assign Selected</b-button>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import AdminDeviceList from './AdminDeviceList'
import AdminBarcodeInput from '@/components/admin/AdminBarcodeInput'

export default {
  name: 'admin-assign-devices',
  components: { AdminBarcodeInput, AdminDeviceList },
  props: {
    modal: String,
    user: Object
  },
  data: function () {
    return {
      showButtons: true,
      deviceList: [],
      selectedDevices: [],
      deviceFields: ['selected', 'imei', 'device_code', 'name', 'device_phone', 'has_subscription', 'subscription_expiry', 'actions']
    }
  },
  created () {
    // TODO - Get a list of unassigned devices
    this.loadDevices()
  },
  methods: {
    loadDevices: async function () {
      let response = await DataProvider.getAdminDeviceList(undefined, { user_id: 0 })
      if (response.success) {
        this.deviceList = response.data.filter(x => x.user_id === null) // filter for unassigned
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    saveChanges: function () {
      if (this.selectedDevices.length === 0) {
        ErrorHelper.displayGeneralErrorToast('You haven\'t selected any devices, so there is nothing to save!', 'Please Make a Selection')
        return
      }
      this.assignDevices(this.selectedDevices)
    },
    assignDevices: async function (devices) {
      try {
        for (let device of devices) {
          let res = await DataProvider.adminAssignDevice(device.device_imei, this.user.email)
          if (res.success) {
            this.$bvToast.toast('The changes you made have been saved!',
              {
                title: 'Changes Saved',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
          } else {
            ErrorHelper.displayDataErrorToast(res)
          }
        }
        this.$emit('save', this.selectedDevices)
      } catch (err) {
        ErrorHelper.displayGeneralErrorToast('An Error Occurred when assigning Device ownership!', 'Error Saving Changes')
        console.error('Device Assignment Error: ', err)
      }
    },
    clickClose: function () {
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    selectChanged: function (newVal) {
      this.selectedDevices = newVal
    },
    saveChangesBarcode: function () {
      if (!this.$refs.deviceInput.validate()) {
        ErrorHelper.displayGeneralErrorToast('Check your input fields, one or more show errors.', 'Errors')
        return
      }
      let devices = this.$refs.deviceInput.devices.filter(x => x.device_imei !== '')
      this.assignDevices(devices)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-assign-devices {
    color: $text-color-invert;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

</style>
